import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

export default function WorkingShpock({ data: { page } }) {
  return (
    <>
      <Helmet>
        <title>Working @ Shpock</title>
      </Helmet>
      <div className="container">
        <GatsbyImage fluid={page.videoPlaceholderImage.fluid} />
        <h2 className="my-4 h1 text-center">{page.headedTitle}</h2>
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBox1.md.html }}
        />
        <div className="gallery">
          {page.imageSection1.map(item => (
            <div className="gallery__item">
              <GatsbyImage fluid={item.fluid} />
            </div>
          ))}
        </div>
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBox2.md.html }}
        />
        <div className="gallery">
          {page.imageSection2.map(item => (
            <div className="gallery__item">
              <GatsbyImage fluid={item.fluid} />
            </div>
          ))}
        </div>
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBox3.md.html }}
        />
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBox4.md.html }}
        />
      </div>
    </>
  )
}

export const query = graphql`
  {
    page: contentfulWorkPage {
      title
      slug
      videoPlaceholderImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      videoIframe {
        videoIframe
      }
      headedTitle
      textBox1 {
        md: childMarkdownRemark {
          html
        }
      }
      textBox2 {
        md: childMarkdownRemark {
          html
        }
      }
      textBox3 {
        md: childMarkdownRemark {
          html
        }
      }
      textBox4 {
        md: childMarkdownRemark {
          html
        }
      }
      imageSection1 {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      imageSection2 {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
